import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'veiculo-item',
  templateUrl: './veiculo-item.component.html',
  styleUrls: ['./veiculo-item.component.scss']
})
export class VeiculoItemComponent implements OnInit {

  @Input("data") data:any = {};

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
