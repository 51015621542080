<div class="menu-action-partial">
      <ul>
            <li>
                <div class="item tooltip-custom" (click)="toFavorites()">
                    <i class="fa-regular fa-heart"></i>
                    <span class="tooltiptext tooltip-bottom">Favoritos</span>
                </div>    
            </li>
          <li>
              <div class="item tooltip-custom" (click)="toCart()">
                  <i class="fa-solid fa-cart-shopping"></i>
                  <span class="tooltiptext tooltip-bottom">Meu Carrinho</span>
              </div>    
          </li>
          <li>
              <div class="item tooltip-custom" (click)="_openLogin()">
                  <i class="fa-regular fa-user"></i>
                  <span class="tooltiptext tooltip-bottom">Fazer Login</span>
              </div> 
          </li> 
          <li>
            <div class="item" href="javascript::void();" title="Menu" (click)="_showMenu()">
                <i class="fa-solid fa-bars"></i>
            </div> 
          </li>
      </ul>
</div> 
<sidebar-menu [showMenu]="showMenu"></sidebar-menu> 
