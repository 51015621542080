<div class="module">
    <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
                <h2 *ngIf="data.mostrar_titulo">{{data.titulo}}</h2>
                <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
          </div>
    </div>
    <div class="module-content">
          <div class="container">
                <div class="products-content">
                      <div class="row">
                            <div class="col-md-6" *ngFor="let p of data.produtos;let y=index;">
                                  <div class="item">
                                        <produto-item-three [data]="p"></produto-item-three>
                                  </div>
                            </div>
                      </div>     
                </div>
                <div class="center">
                      <a routerLink="/servicos/passeios" title="Serviços" class="btn-one btn-icon btn-radius">
                          <span>{{'Ver todos os roteiros disponíveis' | translate}}</span>
                      </a> 
                </div>  
          </div>    
    </div>  
</div>      