import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageErrorComponent } from 'src/app/components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from 'src/app/components/breadcrumbs/breadcrumbs.component';
import { PartialCompraSeguraComponent } from 'src/app/components/partial/partial-compra-segura/partial-compra-segura.component';
import { PartialPageTitleComponent } from 'src/app/components/partial/partial-page-title/partial-page-title.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { BtnFavoritoModule } from '../btn/btn-favorito/btn-favorito.module';
import { InputCheckComponent } from 'src/app/components/input/input-check/input-check.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../pipe/pipe.module';

@NgModule({
  declarations: [
    PageErrorComponent,
    BreadcrumbsComponent, 
    PartialCompraSeguraComponent,
    PartialPageTitleComponent,
    InputCheckComponent
  ],
  exports: [ 
    PageErrorComponent,
    BreadcrumbsComponent,
    PartialCompraSeguraComponent,
    PartialPageTitleComponent,
    InputCheckComponent
  ],
  imports: [
    BtnFavoritoModule, 
    LazyLoadImageModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule
  ]
})
export class PartialModule { }
