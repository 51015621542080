import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss']
})
export class BannerSliderComponent implements OnInit {

  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  @ViewChild("sliderNav") sliderNav:ElementRef;
  public banners       = []; 
  public width         = null;
  public open          = true;
  public indexCurrent = 0;

  constructor(
    private route: Router
  ){

  }  
  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    let self = this;

    $(this.banner.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 1,
      dots: true, 
      arrows: false,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 2500,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 1,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 

    $(this.banner.nativeElement).on('afterChange', function(event, { slideCount: count }, currentSlide, nextSlide){
      
      self.indexCurrent = currentSlide; 

    });


    $(this.banner.nativeElement).slick('refresh'); 

  }
 /**
   * 
   * Open Link
   * 
   */
 openLink(dataItem:any){


  if(!dataItem.link_ativo){
    return false;
  }

  let url:any = null; 


  switch (dataItem.link_tipo) {
    case 1:
      
      url = dataItem.link;

      window.open(url,dataItem.link_target);

    break;
    case 2:
      
      url = this.getLinkProduto(dataItem);

      this.route.navigateByUrl(url);

    break;
    case 3:
      
      url = this.getLinkCategoria(dataItem);

      this.route.navigateByUrl(url);

    break;
  }

}
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * To cidades
   * 
   */
  toCidades(){

    this.route.navigateByUrl("/a-serra-gaucha");   
    
  }
  /**
   * 
   * Prev
   * 
   */
  prevSlide(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  nextSlide(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    this.width = window.innerWidth;

    this.open = false;

    setTimeout(() => {
      this.open = true; 
    },300); 
    setTimeout(() => {
      $(this.banner.nativeElement).slick('refresh');
    },100);
       
  }
  /**
   * 
   * Get image
   * 
   */
  getImage(i){

    let image = null;

    if(this.width <= 700){
      image = i.imagem_mobile;
    }else{
      image = i.imagem; 
    }

    return image;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initBanner();
    },100);
  }

}
