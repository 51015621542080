<div class="modulo modulo-horarios" *ngIf="template == null">
      <h3 class="title-h3" *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
      <div class="horarios">
            <div class="item">
                  <strong>{{data.params.horario_titulo}}</strong>
                  <span>{{data.params.horario_texto}}</span>
            </div>    
      </div>
      <div class="horarios">
            <div class="item">
                  <strong>{{data.params.horario_titulo_2}}</strong>
                  <span>{{data.params.horario_texto_2}}</span>
            </div>    
      </div>
</div>  
<div class="modulo modulo-horarios template-fale-conosco" *ngIf="template == 'fale-conosco'">
      <h2 *ngIf="data.mostrar_titulo">{{data.titulo}}</h2>
      <div class="content">
            <div class="horarios">
                  <div class="item">
                        <strong>{{data.params.horario_titulo}}</strong>
                        <span>{{data.params.horario_texto}}</span>
                  </div>    
            </div>
            <div class="divisor">
                  <div class="linha"></div>
            </div>
            <div class="horarios">
                  <div class="item">
                        <strong>{{data.params.horario_titulo_2}}</strong>
                        <span>{{data.params.horario_texto_2}}</span>
                  </div>    
            </div>
      </div>
</div>  