<header class="header" #header>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-4 col-center">
                    <lg-list></lg-list>
                </div>
                <div class="col-md-4 col-4">
                    <modulo class="content-logo" posicao="logo"></modulo>
                </div>  
                <div class="col-md-4 col-4">
                    <menu-action-partial></menu-action-partial>
                </div>    
            </div>    
        </div>     
    </div>    
</header>  