import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'produto-item-three',
  templateUrl: './produto-item-three.component.html',
  styleUrls: ['./produto-item-three.component.scss']
})
export class ProdutoItemThreeComponent implements OnInit {

  @Input("data") produto:any                  = {};   
  @Input("isLazy") isLazy:any                 = false;    
  @Input("categoria") categoria:any           = null; 
  @Input("index") index:any                   = 0; 
  @Input("nome_lista") nome_lista:any         = "Página de Serviços";
  @Input("classCustom") classCustom:any       = "";
  @Input("apelidoDestino") apelidoDestino     = null;   
  @Input("apelidoCategoria") apelidoCategoria = null; 
  @Input("maxText") maxText                  = "37";
  public link                                 = null;   
  public defaultImage                         = "/images/produto/sem-foto/default.jpg";
  public beneficios                           = [];

  constructor( 
    private gtag: GtagService,
    private route: Router 
  ){
    
    this.isLazy = this.isLazy === true || this.isLazy === "true" ? true : false;  
    
  } 
  /**
   * 
   * Click
   * 
   */
  _click(){

    this.route.navigateByUrl(this.link);

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){

    if(this.apelidoDestino == null){
      
      if(this.categoria ==  null){
        this.link = "/servicos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
      }else{
        this.link = "/servicos/"+this.categoria.apelido+"/"+this.produto.apelido;
      }  
    
    }else{

      this.link = "/destinos/"+this.apelidoDestino+"/"+this.apelidoCategoria+"/"+this.produto.apelido;

    }

  }
  /**
   * 
   * On click
   * 
   */
  onClick(){

    try{
      
      this.route.navigateByUrl(this.link);

      this.gtag.selectProduct({
        id: this.produto.id,
        nome: this.produto.nome,
        categoria: this.produto.categoria_titulo,
        list_name: this.nome_lista,
        list_position: this.index+1 
      });

    }catch(e){
      
    }
  
  }
  /**
   * 
   * Set Benefícios
   * 
   */
  setBeneficios(){

    if(typeof(this.produto.beneficios)){

      this.beneficios = this.produto.beneficios; 

    }

  }
   /**
   * 
   * Formatar foto
   * 
   */
  formatarFoto(){

    this.produto.foto = this.produto.foto != null ? this.produto.foto.replace(".png",".jpg") : null;  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink(); 
  }


}
