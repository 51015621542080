import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboDataSelectComponent } from 'src/app/components/combos/combo-data-select/combo-data-select.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ComboDataSelectComponent 
  ],
  exports: [
    ComboDataSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class ComboDataSelectModule { }
