import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { GtagService } from '../../services/gtag.service';
import { FbService } from '../../services/fb.service';

declare var window:any;
declare var gtag:any;  
declare var $:any;

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'] 
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  
  public isSendAnalytics = false; 
  @ViewChild("containerModulos") containerModulos:any;
  public showContainer:any = false;

  constructor( 
    private app:AppService,
    private data: DataService,
    private gtag: GtagService,
    private fb: FbService 
  ){ }
  

  setMetaTags(){

    let config      = this.data.getConfiguracao();
    let description = config.meta_description;
    
    this.app.setMetaDados(null,description);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    
    this.app.toTop();
    this.setMetaTags(); 
    this.app.loadEnd();
    
  }
  /**
   * 
   * Send analytics
   * 
   */
  sendAnalytics(){

    if(!this.isSendAnalytics){
      
      this.isSendAnalytics = true;
      setTimeout(() => {
      
        this.gtag.sendPage();
        this.fb.init(); 
        this.fb.leadSingle();
              
      },100);

    }

  } 
  /**
   * 
   * Set scroll
   * 
   */
  setScroll(){

    let container = $(this.containerModulos.nativeElement).offset().top;  
    
    $(window).scroll(() => {  
      
      container    = $(this.containerModulos.nativeElement).offset().top;  
      let fromTop  = $(window).scrollTop();
 
      if((parseFloat(fromTop)+800) >= parseFloat(container)){
        this.showContainer = true;  
      }

    });

  }
  /***
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    
    this.setScroll();

  }
  /**
   * 
   * On Destroy
   * 
   */
  ngOnDestroy(): void {
    
  

  }

}
