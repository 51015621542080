import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContatoComponent } from 'src/app/modulos/contato/contato.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    ContatoComponent
  ],
  exports: [
    ContatoComponent
  ],
  imports: [ 
    LazyLoadImageModule,
    CommonModule,
    PipeModule
  ]
})
export class ModuloContatoModule { }
