<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">  
    <modulo-logo [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'whatsapp'">   
    <modulo-whatsapp [data]="m" [classCustom]="classCustom" [template]="template"></modulo-whatsapp>  
  </div>
  <div *ngSwitchCase="'rede-social'">    
    <modulo-rede-social [data]="m" [classCustom]="classCustom" [template]="template"></modulo-rede-social>  
  </div>
  <div *ngSwitchCase="'endereco'">     
    <modulo-endereco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-endereco>  
  </div>
  <div *ngSwitchCase="'banner-slider'">  
    <modulo-banner-slider [data]="m" [classCustom]="classCustom" [template]="template"></modulo-banner-slider> 
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [classCustom]="classCustom" [template]="template"></modulo-newsletter> 
  </div>
  <div *ngSwitchCase="'contato'">   
    <modulo-contato [data]="m" [classCustom]="classCustom" [template]="template"></modulo-contato> 
  </div> 
  <div *ngSwitchCase="'fale-conosco'">  
    <modulo-fale-conosco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-fale-conosco> 
  </div>
  <div *ngSwitchCase="'destinos'">       
    <modulo-destinos [data]="m" [template]="template" [classCustom]="classCustom"></modulo-destinos> 
  </div>
  <div *ngSwitchCase="'produto-destaque-1'">       
    <modulo-produto-destaque-one [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-one> 
  </div>
  <div *ngSwitchCase="'produto-destaque-2'">      
    <modulo-produto-destaque-two [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-two> 
  </div>
  <div *ngSwitchCase="'destaque-blog'">          
    <blog-destaque [data]="m" [template]="template"></blog-destaque> 
  </div>
  <div *ngSwitchCase="'frota'">          
    <modulo-frota [data]="m" [template]="template"></modulo-frota> 
  </div>
  <div *ngSwitchCase="'cliente'">          
    <modulo-cliente [data]="m" [template]="template"></modulo-cliente> 
  </div>
  <div *ngSwitchCase="'sobre'">      
    <modulo-sobre [data]="m" [template]="template"></modulo-sobre> 
  </div>
  <div *ngSwitchCase="'horarios'">       
    <modulo-horarios [data]="m" [template]="template"></modulo-horarios> 
  </div>
  <div *ngSwitchCase="'menu-main'">       
    <modulo-menu-main [data]="m" [template]="template"></modulo-menu-main> 
  </div>
</div>
  
  
  