<div (click)="_click()" class="destino-item" [ngStyle]="{'background-image': 'url('+formatImage(data.imagem)+')'}">
      <a href="javascript::void();" title="{{data.nome}}">
            <div class='content'>
                  <div class="body center">
                        <h3 class="name">{{data.nome}}</h3>
                  </div>
            </div>      
            <!--<div class="bg"></div>-->
      </a>   
</div>      
