import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit,AfterViewInit {

  @Input("data") data:any           = {};
  @Input("template") template:any   = "";
  @Input("classCustom") classCustom = "";
  @ViewChild("slider") banner:ElementRef;
  
  constructor(
    private router: Router
  ) { }
  
 
  /**
   * 
   * More
   * 
   */
   more(item:any){

    if(item.show){
      item.show = false;
    }else{
      item.show = true;
    }

  }
  /**
   * 
   * Inicializa o banner
   * 
   */
   initBanner(){

    $(this.banner.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 2500,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}  
  /**
   * 
   * Init
   * 
   **/
  ngAfterViewInit(): void {
    this.initBanner(); 
  }

}
