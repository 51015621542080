<div class="modal-message">
  <div class="modal-message-content">
    <article class="message" [innerHtml]="data.message"></article>
  </div>
  <div class="modal-message-footer">
      <div class="center">
        <button type="button" class="btn-one btn-radius" (click)="dialog.close()">
          <span>OK</span>
        </button>   
      </div> 
  </div>    
</div>
