import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnGoogleLoginComponent } from 'src/app/components/btns/btn-google-login/btn-google-login.component';
import { BtnFacebookLoginComponent } from 'src/app/components/btns/btn-facebook-login/btn-facebook-login.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { LoaderModule } from '../../loader/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipeModule } from '../../pipe/pipe.module';  

@NgModule({
  declarations: [
    BtnGoogleLoginComponent,
    BtnFacebookLoginComponent
  ],
  exports: [
    BtnGoogleLoginComponent,
    BtnFacebookLoginComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    LazyLoadImageModule,
    PipeModule
  ]
})
export class BtnSocialLoginModule { }
