import { Component, OnInit, Input, ViewChild,ElementRef,EventEmitter } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { GtagService } from 'src/app/services/gtag.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { TranslateService } from 'src/app/services/translate.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'modulo-fale-conosco', 
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  @Input("data") data:any           = {};    
  @Input("template") template       = "one";
  @Input("classCustom") classCustom = "";
  public dataItem:any               = {};
  public loader                     = false;
  public subscribeMessage           = "";
  public showArea                   = true;
  @ViewChild("textarea") textarea:ElementRef;
  public clearDate                  = new EventEmitter();
  public moduloHorarios             = null;
  public moduloContato              = null;

  constructor(
    private api: ApiService,
    private app: AppService,
    private validator: ValidatorService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translate: TranslateService,
    private modulo: ModuloService
  ){ }

  /**
   * 
   * Send data
   * 
   */
  send(){

    try{ 

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe seu Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe seu E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado possui um formato inválido.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe seu Telefone.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Informe uma Mensagem.");

      if(!this.validator.passes()){   
        this.app.info(this.validator.getMessagesHtml());
        return false;
      }

      this.loader   = true;
      
      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;

          this.api.contato().send(this.dataItem).subscribe(response => {

            this.loader = false;

            if(response.status == 1){
              
              this.initDataItem();
              this.clearDate.emit(null);
              this.app.info("Recebemos a sua mensagem. Em breve entraremos em contato.");  

            }else{
              
              let error = this.api.formatError(response.data);
              this.app.info(error.message);  

            }

          },(response) => {

            this.loader = false;   
            let error   = this.app.formatError(response);  
            this.app.info(error.message);


          });

        },(response) => { 

          this.loader   = false;
          this.app.info(this.translate.getTraducaoText("Token de validação inválido. Recarregue a página para continuar."));
  
        });

    }catch(e){

      this.loader = false;
      

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      date_in: "",
      date_out: "",
      tipo: 2,
      apelido: "fale-conosco",
      require_telefone: false
    }

    if(this.template === "two"){
      this.dataItem.require_telefone = true;
    }

  }
  /***
   * 
   * Change Date
   * 
   */
  changeDate(data){

    this.dataItem.date_in  = data.date_in; 
    this.dataItem.date_out = data.date_out;    

  }
  /***
   * 
   * Set show area
   * 
   */
  setShowArea(){

    this.showArea = false;

    setTimeout(() => {
      this.textarea.nativeElement.focus();
    },200)
  
  }
  /**
   * 
   * Get img
   * 
   */
   getImg(){

    return "/images/modulo/atendimento/"+this.data.params.imagem;

  }
  /**
   * 
   * Ouvintes
   * 
   */
   onData(){ 

    this.modulo.updateModulosEmitter.subscribe(data =>{

      let modulos = this.modulo.filterModulosByPosition(data,"horarios");
      
      if(modulos.length > 0){
        this.moduloHorarios = modulos[0];
      }

      modulos = this.modulo.filterModulosByPosition(data,"contato");
      
      if(modulos.length > 0){
        this.moduloContato = modulos[0]; 
      }


    });
    this.modulo.getModulosEmitter.emit(); 
    
  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.initDataItem(); 
    this.onData();
  }

}
