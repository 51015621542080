<div class="modulo-rede-social" *ngIf="template == null">
  <h3 *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
  <div class="contato-item">
    <ul class="list-rede-social">
      <li *ngIf="data.params.ativar_tripadvisor"> 
        <a [href]="data.params.tripadvisor" title="TripAdvisor" target="_blank">
          <img src="images/icons/tripadvisor.png" alt="Trip Advisor" width="20px" height="20px" />
        </a>
      </li> 
      <li *ngIf="data.params.ativar_linkedin">
        <a [href]="data.params.linkedin" title="Linkedin" target="_blank">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
      </li>
      <li *ngIf="data.params.ativar_facebook">
        <a [href]="data.params.facebook" title="Facebook" target="_blank">
          <i class="fa-brands fa-facebook-f"></i>
        </a>
      </li>
      <li *ngIf="data.params.ativar_instagram">
        <a [href]="data.params.instagram" title="Intstagram" target="_blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
      </li>   
    </ul> 
  </div>
</div>
<div class="modulo-rede-social template-blog card-item" *ngIf="template == 'blog'">
  <h3 *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
  <div class="contato-item">
    <ul class="list-rede-social">
      <li *ngIf="data.params.ativar_facebook">
        <a [href]="data.params.facebook" title="Facebook" target="_blank">
          <i class="fa-brands fa-facebook-f"></i>
        </a>
      </li>
      <li *ngIf="data.params.ativar_instagram">
        <a [href]="data.params.instagram" title="Intstagram" target="_blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
      </li>
      <li *ngIf="data.params.ativar_linkedin">
        <a [href]="data.params.linkedin" title="Linkedin" target="_blank">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
      </li>
      <li class="tripadvisor" *ngIf="data.params.ativar_tripadvisor"> 
          <a [href]="data.params.tripadvisor" title="TripAdvisor" target="_blank">
            <img src="images/icons/tripadvisor.png" alt="Trip Advisor" width="20px" height="20px" />
          </a>
      </li>    
    </ul> 
  </div>
</div>

