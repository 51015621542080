import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerSliderComponent } from 'src/app/modulos/banner-slider/banner-slider.component';

@NgModule({
  declarations: [
    BannerSliderComponent
  ],
  exports: [
    BannerSliderComponent
  ],
  imports: [
    CommonModule
  ]
})  
export class ModuloBannerSliderModule{}
