import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { StorageService } from 'src/app/services/storage.service';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'usuario-login-modal',
  templateUrl: './usuario-login-modal.component.html',
  styleUrls: ['./usuario-login-modal.component.scss']
})
export class UsuarioLoginModal implements OnInit {

  public dataItem:any         = {};  
  public dataItemCadastro:any = {};  
  public content              = 1;
  public title                = "Login";
  public loader               = false; 
  public number1              = 0;
  public number2              = 0;
  public soma                 = null;
  public usuarioService:any   = null;

  constructor(
    public dialog: MatDialogRef<UsuarioLoginModal>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ){ 

    this.content = typeof(this.data.content) != "undefined" ? this.data.content : 1;  
    
    if(this.data.usuarioService){
      this.usuarioService = this.data.usuarioService;  
    }

  }
  /**
   * 
   * Update login
   * 
   * @param usuario 
   * 
   */
  _updateLogin(usuario){

    this.data.usuarioService.emitUsuarioLogin.emit(usuario);
    this._close();

  }
  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close(); 

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit():void{}

}
