import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from 'src/app/services/translate.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'usuario-login',
  templateUrl: './usuario-login.component.html',
  styleUrls: ['./usuario-login.component.scss']
})
export class UsuarioLoginComponent implements OnInit {  

  public dataItem:any         = {};  
  public dataItemCadastro:any = {};
  public content      = 1;
  public title        = "Faça o login e acesse a sua conta";
  public loader       = false; 
  public linguagem    = "pt-BR";
  @Output("updateLogin") updateLogin:any = new EventEmitter(); 
  
  constructor(
    public usuarioService: UsuarioService,
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService,
    private storage: StorageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translate: TranslateService,
    private data: DataService
  ){ 
    
    this.linguagem = this.data.getLinguagem();
    
  }
  /**
   * 
   * Update login
   * 
   * @param usuario 
   * 
   */
  _updateLogin(usuario){

    this.updateLogin.emit(usuario); 

  }
  /**
   * 
   * Verify email
   * 
   */
  verifyEmail(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.email,"email",this.translate.getTraducaoText("- Informe seu e-mail."));
      this.validator.isEmail(this.dataItem.email,"email",this.translate.getTraducaoText("- O E-mail informado é inválido."));

      if(!this.validator.passes()){

        let error = this.validator.getMessagesHtml();
        this.app.info(error);
        return false;
      
      }
      this.loader = true;

      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => { 

        this.dataItem.token = token; 

        this.api.loja().verifyEmail(this.dataItem).subscribe(response => {

          this.loader = false;

          if(response.status == 1){

            if(response.data === true || response.data === 'true'){

              this.setContent(2); 

            }else{

              this.setContent(3);
              this.dataItemCadastro.email = this.dataItem.email;

            }

          }else{

            let error = this.translate.getTraducaoText("Houve um problema ao logar. Tente novamente ou entre em contato com nossa equipe.");
            this.app.info(error);

          }

        },(response) => {

          this.loader = false;
          let errors  = this.api.formatError(response); 

          this.app.info(errors.message);

        });

      },(response) => {

        this.loader         = false; 
        let messages        = this.translate.getTraducaoText("Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.");
        this.app.info(messages); 

      });


    }catch(e){

      this.loader = false;
      this.app.info(e.message);

    }

  }
  /**
   * 
   * Realiza o login
   * 
   */
  entrar(){

    try{

      this.loader = true; 

      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => { 

        this.dataItem.token = token;

        this.api.loja().login(this.dataItem).subscribe(response => {

          this.loader = false;

          if(response.status == 1){

            this.storage.setUsuario(response.data);
            this.usuarioService.emitUsuarioLogin.emit(response.data); 
   
          }else{

            let error = this.translate.getTraducaoText("Houve um problema ao logar. Tente novamente ou entre em contato com nossa equipe.");
            this.app.info(error);

          }

        }, (response) => {

          this.loader = false;
          let errors  = this.api.formatError(response); 

          this.app.info(errors.message);

        });
      
      },response => {

        this.loader         = false; 
        let messages        = this.translate.getTraducaoText("Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.");
        this.app.info(messages); 

      });

    }catch(e){

      this.app.info(e.message);

    }

  }
  /**
   * 
   * Send E-mail
   * 
   */
  sendEmail(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.email,"email",this.translate.getTraducaoText("- Informe o E-mail."));
      this.validator.isEmail(this.dataItem.email,"email",this.translate.getTraducaoText("- O E-mail informado é inválido."));

      if(!this.validator.passes()){ 
        let errors = this.validator.getMessagesHtml();
        this.app.info(errors); 
      }else{

        this.loader = true;  

        this.api.loja().email_senha(this.dataItem.email).subscribe(response => {

          this.loader = false;

          if(response.status == 1){  

            let message = this.translate.getTraducaoText("Verifique o código que foi enviado para o seu e-mail.");
            this.app.info(message); 
            this.setContent(5);   

          }else{

            let erro = this.translate.getTraducaoText("Houve um erro e não foi possível completar a requisição. Tente novamente.");
            this.app.info(erro);

          }

        },(response) => {

          this.loader = false;
          let error   = this.app.formatError(response);
          this.app.info(error.message); 

        });

      }

    }catch(e){
  
      this.loader = false;
      this.app.info(e.message);

    }

  }
  /**
   * 
   * Cadastrar
   * 
   */
  cadastrar(){

    try{

      this.validator.clear();  
      this.validator.isEmpty(this.dataItemCadastro.nome,"nome",this.translate.getTraducaoText("- Informe o Nome."));
      this.validator.isEmpty(this.dataItemCadastro.email,"email",this.translate.getTraducaoText("- Informe o E-mail."));
      this.validator.isEmail(this.dataItemCadastro.email,"email",this.translate.getTraducaoText("- E-mail inválido."));
      this.validator.isEmpty(this.dataItemCadastro.senha,"senha",this.translate.getTraducaoText("- Informe a Senha."));  
      this.validator.isEmpty(this.dataItemCadastro.data_nascimento,"data_nascimento",this.translate.getTraducaoText("- Informe o seu Nascimento."));
      this.validator.isEmpty(this.dataItemCadastro.telefone,"telefone",this.translate.getTraducaoText("- Informe o Celular."));
      this.validator.isEmpty(this.dataItemCadastro.cpf,"cpf",this.translate.getTraducaoText("- Informe o CPF."));
      this.validator.validCpf(this.dataItemCadastro.cpf,"cpf",this.translate.getTraducaoText("- Informe o CPF."));
      this.validator.isEmpty(this.dataItemCadastro.rg,"rg",this.translate.getTraducaoText("- Informe o RG."));   

      if(this.validator.passes()){

        this.loader = true;

        this.recaptchaV3Service.execute('importantAction')
       .subscribe((token) => { 

          this.dataItem.token = token;

          this.api.loja().usuario().store(this.dataItemCadastro).subscribe(response => {

            this.loader = false;

            if(response.status == 1){ 
              
              this.usuarioService.emitUsuarioLogin.emit(response.data);
              this.storage.setUsuario(response.data);
              this.updateLogin.emit(response.data);
              
              this.app.info(this.translate.getTraducaoText("Seu cadastro foi realizado com sucesso."));
       

            }else{

              this.app.info(this.translate.getTraducaoText("Houve um problema no processamento. Tentenovamente ou informe a nossa equipe.")); 

            }

          }, (response) => {

            this.loader = false;
            let error   = this.api.formatError(response);

            this.app.info(error.message); 

          });

        },(response) => {

          this.loader         = false; 
          let messages        = this.translate.getTraducaoText("Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.");
          this.app.info(messages); 

        });

      }else{

        let errors = this.validator.getMessagesHtml();
        this.app.info(errors); 

      }

    }catch(e){

      this.loader = false;
      this.app.info(e.message);

    }


  }
  /**
   * 
   * Init dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      email: "", 
      senha: "",
      codigo: "",
      token: null,
      senha_nova: ""
    }

  }
  /**
   * 
   * Init dataItem
   * 
   */
  initDataItemCadastro(){

    this.dataItemCadastro = {
      nome: "",
      email: "",
      data_nascimento: "",
      senha: "",
      telefone: "",
      cpf: "",
      rg: "",
      token: null,
      tipo_nacionalidade: 1,
      brasileiro: true,
      estrangeiro: false
    }

  }
  /**
   * 
   * Set estrangeiro
   * 
   */
  setEstrangeiro(){

    this.dataItemCadastro.tipo_nacionalidade = 2,
    this.dataItemCadastro.estrangeiro = true;
    this.dataItemCadastro.brasileiro = false;

  }
  /**
   * 
   * Set brasileiro
   * 
   */
  setBrasileiro(){

    this.dataItemCadastro.tipo_nacionalidade = 1,
    this.dataItemCadastro.estrangeiro = false;
    this.dataItemCadastro.brasileiro  = true;

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.usuarioService.emitUsuarioLogin.subscribe(usuario => {

      if(usuario != null){
      
      }

    });

  }
  /**
   * 
   * Alterar senha
   * 
   * @param value 
   * 
   */
  alterarSenha(){

    try{

      this.validator.clear();


      this.validator.isEmpty(this.dataItem.email,"email",this.translate.getTraducaoText("- Informe o E-mail."));
      this.validator.isEmpty(this.dataItem.codigo,"codigo",this.translate.getTraducaoText("- Informe o Código."));
      this.validator.isEmpty(this.dataItem.senha_nova,"senha_nova",this.translate.getTraducaoText("- Informe a Nova Senha."));
 
      if(this.validator.passes()){

        this.loader = true; 

        this.recaptchaV3Service.execute('importantAction')
       .subscribe((token) => { 

          this.dataItem.token = token;

          this.api.loja().update_senha(this.dataItem).subscribe(response => {

            this.loader = false;

            if(response.status == 1){

              this.content = 1;
              this.initDataItem();
              this.app.info(this.translate.getTraducao("A sua senha foi alterada com sucesso.",this.linguagem),"success");

            }else{  

              this.app.info(this.translate.getTraducao("Houve um problema ao alterar a senha. Tente novamente.",this.linguagem));

            }

          },(response) => {

            this.loader = false;
            let error   = this.api.formatError(response);
            this.app.info(error.message);

          });

        },response => {

          this.loader         = false; 
          let messages        = this.translate.getTraducao("Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.",this.linguagem);
          this.app.info(messages); 

        });

      }else{

        this.app.info(this.validator.getMessagesHtml()); 

      }


    }catch(e){

      this.app.info(e.message);

    }

  }
  /**
   * 
   * Set content
   * 
   * @param value 
   * 
   */
  setContent(value){

    this.content = value; 

    switch(this.content){
      case 1:
        this.title = "Login";
      break;
      case 2:
        this.title = "Recuperação de senha";
      break;
      case 4: 
        this.title = "Cadastro de Usuário";
      break;
    }

  }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit():void{

    this.initDataItem();
    this.initDataItemCadastro(); 
    this.onData();

  }


}
