import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuarioLoginModal } from '../../usuario/usuario-login-modal/usuario-login-modal.component';

@Component({
  selector: 'menu-action-partial',
  templateUrl: './menu-action-partial.component.html',
  styleUrls: ['./menu-action-partial.component.scss']
})
export class MenuActionPartialComponent implements OnInit {

  public showMenu = new EventEmitter();
  
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private storage: StorageService,
    public usuarioService: UsuarioService
  ){}

  /**
   * 
   * Show menu
   * 
   */
  _showMenu(){

    this.showMenu.emit(null); 

  }
  /**
   * 
   * To Cart
   * 
   **/
  toCart(){

    this.router.navigateByUrl("/carrinho"); 

  }
  /**
   * 
   * To Favorites
   * 
   **/
  toFavorites(){

    this.router.navigateByUrl("/minha-conta/favoritos");  

  }
  /**
   * 
   * Open Login
   * 
   **/
  _openLogin(){
      
    let usuario = this.storage.getUsuario();

    if(usuario == null){

      this.dialog.open(  
        UsuarioLoginModal,
        {
          width: "500px",
          data: {
            usuarioService: this.usuarioService,
            content: 1
          }
        }
      );

    }else{

      this.router.navigateByUrl("/minha-conta"); 

    }

  }
  /***
   * 
   * Init 
   * 
   ***/
  ngOnInit():void{}

}
