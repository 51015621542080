import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from 'src/app/modulos/cliente/cliente.component';
import { PartialModule } from '../../partial/partial.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { PipeModule } from '../../pipe/pipe.module';
import { ClienteItemModule } from '../../cliente/cliente-item/cliente-item.module';

@NgModule({
  declarations: [
    ClienteComponent
  ],
  exports: [
    ClienteComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    LazyLoadImageModule,
    PipeModule,
    ClienteItemModule 
  ] 
})
export class ModuloClienteModule{}
