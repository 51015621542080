import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SobreComponent } from 'src/app/modulos/sobre/sobre.component';
import { PartialModule } from '../../partial/partial.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    SobreComponent
  ],
  exports: [
    SobreComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    PipeModule
  ]
})
export class ModuloSobreModule{}
