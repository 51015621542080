<div class='lg-list'>
    <div class="selected" (click)="_show()"> 
        <i class="fa-solid fa-globe"></i>
        <!--<img src='/images/icons/linguagem/{{codigo}}.svg' alt="Português" width="40px" />-->
    </div> 
    <div class="menu-select animated slideInDown" [hidden]="!show"> 
        <div class="content">
            <div class="item" *ngFor="let i of list" (click)="_select(i)" [ngClass]="{'active': codigo == i.codigo }">
                <img src='/images/icons/linguagem/{{i.codigo}}.svg' width="25px" height="25px" />
                <span>{{i.titulo}}</span>
            </div>    
        </div>
    </div>
</div>   
<loader *ngIf="loader"></loader> 