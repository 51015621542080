import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { ModuloNewsletterModule } from './modulo-newsletter/modulo-newsletter.module';
import { ProdutoDestaqueOneComponent } from 'src/app/modulos/produto-destaque-one/produto-destaque-one.component';
import { ProdutoDestaqueTwoComponent } from 'src/app/modulos/produto-destaque-two/produto-destaque-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { ModuloDestinosComponent } from 'src/app/modulos/modulo-destinos/modulo-destinos.component';
import { DestinoItemModule } from '../destinos/destino-item/destino-item.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { ModuloFaleConoscoModule } from './modulo-fale-conosco/modulo-fale-conosco.module';
import { ProdutoClassificacaoItemModule } from '../produto/produto-classificacao-item/produto-classificacao-item.module';
import { FrotaComponent } from '../../modulos/frota/frota.component';
import { RouterModule } from '@angular/router';
import { ModuloBannerSliderModule } from './modulo-banner-slider/modulo-banner-slider.module';
import { ModuloClienteModule } from './modulo-cliente/modulo-cliente.module';
import { ModuloSobreModule } from './modulo-sobre/modulo-sobre.module';
import { ModuloHorariosModule } from './modulo-horarios/modulo-horarios.module';
import { ModuloMenuMainModule } from './modulo-menu-main/modulo-menu-main.module';
import { VeiculoItemComponent } from 'src/app/components/veiculo/veiculo-item/veiculo-item.component';

@NgModule({
  declarations: [
    Modulo,
    ProdutoDestaqueOneComponent,
    ProdutoDestaqueTwoComponent,
    ModuloDestinosComponent,
    EnderecoComponent,
    FrotaComponent,
    VeiculoItemComponent
  ],
  exports:[
    Modulo,
    EnderecoComponent,
    FrotaComponent
  ],
  imports: [
    LazyLoadImageModule,
    RouterModule,
    ModuloFaleConoscoModule,
    ModuloWhatsappModule,  
    DestinoItemModule,
    ProdutoPartialModule,
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    ModuloNewsletterModule,
    CommonModule,
    PipeModule, 
    ModuloSobreModule,   
    ProdutoClassificacaoItemModule,
    ModuloBannerSliderModule,
    ModuloClienteModule,
    ModuloMenuMainModule
  ]
})
export class ModuloModule{}
