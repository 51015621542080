<div class="usuario-login" [ngSwitch]="content"> 
    <div class="box" *ngSwitchCase="'1'">
        <div class="item">
            <form class="form form-custom" (submit)="verifyEmail()">
                <article class="description">  
                    <p>{{'Faça o seu login informando o seu e-mail e senha ou através da rede social do google e facebook.' | translate}}</p>
                </article>   
                <div class="input-box">
                    <label class="bold">{{'Informe seu e-mail' | translate}}:</label>
                    <input type="text" placeholder="Informe aqui o seu login" [(ngModel)]="dataItem.email" name="email"  class="input grey w-input" />
                </div>
            </form>
        </div> 
        <div class="item item-button">
            <button type="button" class="btn btn-one" (click)="verifyEmail()">
                <span>{{'Avançar' | translate}}</span>
            </button> 
        </div>
        <div class="item item-button-social">
            <!--<btn-facebook-login (updateLogin)="_updateLogin($event)"></btn-facebook-login>-->
            <btn-google-login (updateLogin)="_updateLogin($event)"></btn-google-login>
        </div>    
    </div>
    <div class="box" *ngSwitchCase="'2'">
        <div class="item">
            <form class="form form-custom">
                <article class="description">
                    <p>Digite sua senha para fazer login em sua conta. Caso tenha esquecido, <strong (click)="setContent(4)">recupere sua senha aqui.</strong></p>
                </article>
                <div class="input-box">
                    <label class="bold">{{'Informe seu e-mail' | translate}}:</label>
                    <input type="text" placeholder="Informe aqui o seu login" [(ngModel)]="dataItem.email" name="email"  class="input grey w-input" />
                </div>
                <div class="input-box">
                    <label class="bold">{{'Informe sua Senha' | translate}}:</label>
                    <input type="password" [(ngModel)]="dataItem.senha" name="senha"  />
                </div>
            </form> 
        </div>   
        <div class="item item-button">
            <button type="button" class="btn btn-four" (click)="setContent(1)">
                <span>{{'Voltar' | translate}}</span>
            </button>
            <button type="button" class="btn btn-one" (click)="entrar()">
                <span>{{'Entrar' | translate}}</span>
            </button> 
        </div>
    </div>
    <div class="box" *ngSwitchCase="'3'">
        <form class="form-custom" (submit)="cadastrar()">
            <div class="item">
                <div class="input-box">
                    <label class='bold'>{{'Informe seu nome' | translate}}*:</label>
                    <input type="text" class="input grey w-input" [(ngModel)]="dataItemCadastro.nome" name="nome" autocomplete="_nome" />
                </div>
                <div class="input-box">
                    <label class='bold'>Informe seu e-mail*:</label>
                    <input type="text" class="input grey w-input" [(ngModel)]="dataItemCadastro.email" name="email" autocomplete="_email" />
                </div>
                <div class="input-box">  
                    <label>{{'Informe uma senha' | translate}}*</label>
                    <input type="password" [(ngModel)]="dataItemCadastro.senha" name="password" />
                </div>  
                <hr>
                    <h3 class="title-h3">{{'Nacionalidade' | translate}}</h3>
                    <span class="title-info">{{'Informe se você é brasileiro(a) ou estrangeiro(a).' | translate}}</span> 
                    <div class="row">
                        <div class="col-md-3">   
                            <input-check (change)="setBrasileiro()" [(ngModel)]="dataItemCadastro.brasileiro" label="{{'Brasileiro(a)' | translate}}"></input-check>
                        </div>
                        <div class="col-md-3">    
                            <input-check (change)="setEstrangeiro()" [(ngModel)]="dataItemCadastro.estrangeiro" label="{{'Estrangeito(a)' | translate}}"></input-check>
                        </div>
                    </div>
                <hr>
                <div class="content-data" *ngIf="dataItemCadastro.tipo_nacionalidade == 1">
                    <div class="input-box">
                        <label class='bold'>{{'Data de Nascimento' | translate}}:</label>  
                        <input type="text" placeholder="dd/MM/yyyy" class="input grey w-input" [(ngModel)]="dataItemCadastro.data_nascimento" name="data_nascimento" autocomplete="_data_nascimento" mask="99/99/9999" />
                    </div>
                    <div class="input-box input-box-senha"> 
                        <label class='bold'>{{'Informe seu celular' | translate}}:</label> 
                        <input type="text" placeholder="(00) 00000-0000" class="input grey w-input" [(ngModel)]="dataItemCadastro.telefone" name="telefone" autocomplete="_telefone" mask="(99) 99999-9999" />
                    </div>
                    <div class="input-box">
                        <label class='bold'>{{'Informe seu CPF' | translate}}:</label>  
                        <input type="text" placeholder="000.000.000-00" class="input grey w-input" [(ngModel)]="dataItemCadastro.cpf" name="cpf" autocomplete="_cpf" mask="999.999.999-99" />
                    </div>
                    <div class="input-box">
                        <label class='bold'>{{'Informe seu RG' | translate}}*:</label>   
                        <input type="text" class="input grey w-input" [(ngModel)]="dataItemCadastro.rg" name="rg" autocomplete="_rg" />
                    </div>
                </div>
                <div class="content-data" *ngIf="dataItemCadastro.tipo_nacionalidade == 2">
                    <div class="input-box">
                        <label class='bold'>{{'Data de Nascimento' | translate}}:</label>  
                        <input type="text" placeholder="yyyy/MM/dd" class="input grey w-input" [(ngModel)]="dataItemCadastro.data_nascimento" name="data_nascimento" autocomplete="_data_nascimento" mask="9999/99/99" />
                    </div>
                    <div class="input-box input-box-senha"> 
                        <label class='bold'>{{'Informe seu celular' | translate}}:</label> 
                        <input type="text" placeholder="(DDI)(DDD) ****-****)" class="input grey w-input" [(ngModel)]="dataItemCadastro.telefone" name="telefone" autocomplete="_telefone" mask="(99) (99) 9999-9999" />
                    </div>
                    <div class="input-box">
                        <label class='bold'>{{'Informe seu PASSAPORTE' | translate}}:</label>  
                        <input type="text" class="input grey w-input" [(ngModel)]="dataItemCadastro.passaporte" name="cpf" autocomplete="_passaporte" />
                    </div>
                </div>
            </div>
            <div class="item item-button">
                <button type="button" class="btn btn-four" (click)="setContent(1)">
                    <span>{{'Voltar' | translate}}</span>
                </button>
                <button type="submit" class="btn btn-one">  
                    <span>{{'Cadastrar' | translate}}</span>
                </button>      
            </div>
        </form> 
    </div>
    <div class="box" *ngSwitchCase="'4'">
        <div class="item">
            <form class="form form-custom">
                <article class="description">
                    <p>Informe o e-mail que você deseja receber o código.</p>
                </article>
                <div class="input-box">
                    <label class="bold">{{'Informe seu e-mail' | translate}}:</label>
                    <input type="text" placeholder="Informe aqui o seu login" [(ngModel)]="dataItem.email" name="email"  class="input grey w-input" />
                </div>
            </form>    
        </div>
        <div class="item item-button">
            <button type="button" class="btn btn-four" (click)="setContent(2)">
                <span>{{'Voltar' | translate}}</span>
            </button>
            <button type="button" class="btn btn-one" (click)="sendEmail()">
                <span>{{'Enviar' | translate}}</span>
            </button>   
            <button type="button" class="btn btn-four" (click)="setContent(5)">
                <span>{{'Tenho um Código' | translate}}</span>
            </button> 
        </div>    
    </div>
    <div class="box" *ngSwitchCase="'5'">
        <div class="item">
            <form class="form form-custom">
                <article class="description">      
                    <p>{{'Informe o código que você recebeu no seu e-mail e a nova senha.' | translate}}</p>
                </article>
                <div class="input-box">
                    <label class="bold">{{'Informe o código enviado no seu e-mail' | translate}}:</label>  
                    <input type="text" [(ngModel)]="dataItem.codigo" name="codigo" autocomplete="_codigo" integer />
                </div>
                <div class="input-box">
                    <label class='bold'>{{'Informe a nova senha' | translate}}:</label>  
                    <input type="password" [(ngModel)]="dataItem.senha_nova" name="senha_nova" autocomplete="_senha_nova"  />
                </div>
            </form>     
        </div>
        <div class="item item-button">
            <button type="button" class="btn btn-four" (click)="setContent(4)">
                <span>{{'Voltar' | translate}}</span>
            </button>      
            <button type="button" class="btn btn-one" (click)="alterarSenha()">
                <span>{{'Alterar Senha' | translate}}</span>
            </button> 
        </div>    
    </div>
</div>
<loader *ngIf="loader"></loader>  

  