<div class="modulo banner-slider" (window:resize)="onResize()">
  <div class="legend" *ngFor="let i of data.banners;let y = index">
    <div class="title-flow left" *ngIf="i.banner_titulo_ativo && indexCurrent == y">
      <div class="title-content">   
        <span class="title slide-top" *ngIf="i.banner_titulo != '' && i.banner_titulo != null">{{i.banner_titulo}}</span>
        <span class="subtitle" *ngIf="i.banner_subtitulo != '' && i.banner_subtitulo != null">{{i.banner_subtitulo}}</span>
        <div class="content-button" *ngIf="i.link_cta_ativo">
          <button type="button" (click)="openLink(i)">  
            <strong>
              {{i.link_text}}
            </strong>
          </button>
        </div>
      </div>
    </div>
  </div>  
    <div #banner class="banner-slider-slick-content">
        <div *ngFor="let i of data.banners;let y = index">
          <div class="item slider-item" *ngIf="!i.link_ativo">
            <img *ngIf="width <= 700" width="100%"  [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%"  [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
          <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
            <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%"  [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%"  [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>  
            </a>
            <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>   
            </a> 
            <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>  
            </a>   
          </div>
        </div>
      </div>
</div>
    
    
    