<div class="content-linha">
      <div class="container">
            <div class="linha"></div>
      </div>
</div>
<div class="module modulo-cliente">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <div class="content-title">
                        <div class="box">
                              <h3>{{data.titulo}}</h3>
                        </div>
                        <div class="box">
                              <div class="navigate">
                                    <div class="arrow" (click)="prev()"> 
                                          <img src="/images/icons/arrow-left.svg" alt="Anterior" width="17px" height="17px" />
                                    </div>
                                    <div class="arrow" (click)="next()">
                                          <img src="/images/icons/arrow-right.svg" alt="Próximo" width="17px" height="17px" />
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div> 
      <div class="modulo-content">
            <div class="container">
                  <div class="slider-itens" #slider>
                        <div class="slider-item" *ngFor="let d of data.clientes">
                            <cliente-item [data]="d"></cliente-item>   
                        </div>
                  </div> 
            </div>      
      </div>      
</div>      