<div class="modal">
    <div class="modal-title">
        <span class="title">{{'Faça o seu login na Suprematur' | translate}}</span>
        <div class="close" (click)="dialog.close()">
            <i class="fa-solid fa-xmark"></i>
        </div>     
    </div>    
    <div class="modal-content">
        <usuario-login (updateLogin)="_updateLogin($event)"></usuario-login>
    </div>    
</div>    
<loader *ngIf="loader"></loader>
