import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteItemComponent } from 'src/app/components/cliente/cliente-item/cliente-item.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [ 
    ClienteItemComponent
  ],
  exports: [
    ClienteItemComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    LazyLoadImageModule
  ]
})
export class ClienteItemModule{}
