import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmInputComponent } from 'src/app/components/input/dm-input/dm-input.component';
import { InputDateRangeComponent } from 'src/app/components/input/input-date-range/input-date-range.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DmInputComponent,
    InputDateRangeComponent
  ],
  exports: [
    DmInputComponent,
    InputDateRangeComponent
  ],
  imports: [ 
    CommonModule,
    FormsModule,
    ReactiveFormsModule    
  ]
})
export class DmInputModule{}
