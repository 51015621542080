import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss']
})
export class HorariosComponent implements OnInit {

  @Input("data") data:any = null;
  @Input("template") template = null; 
  @Input("classCustom") classCustom;   

  constructor(){}

  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}

}
