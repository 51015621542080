import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cliente-item',
  templateUrl: './cliente-item.component.html',
  styleUrls: ['./cliente-item.component.scss']
})
export class ClienteItemComponent implements OnInit {
  
  @Input("data") data:any = {};

  constructor(){}  

  /**
   * 
   * More
   * 
   */
   more(){

    if(this.data.show){
      this.data.show = false; 
    }else{
      this.data.show = true;
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}  

}
