import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemComponent } from 'src/app/components/produto/produto-item/produto-item.component';
import { ProdutoItemTwoComponent } from 'src/app/components/produto/produto-item-two/produto-item-two.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoItemValorModule } from '../produto-item-valor/produto-item-valor.module';
import { ProdutoSearchComponent } from '../../../components/produto/produto-search/produto-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialModule } from '../../partial/partial.module';
import { LoaderModule } from '../../loader/loader/loader.module';
import { ComboDataSelectModule } from '../../combo/combo-data-select/combo-data-select.module';
import { SelectDataModule } from '../../combo/select-data/select-data.module';
import { ProdutoItemThreeComponent } from 'src/app/components/produto/produto-item-three/produto-item-three.component';

@NgModule({
  declarations: [
    ProdutoItemComponent,
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoItemThreeComponent,
    ProdutoSearchComponent
  ],
  exports: [ 
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoItemComponent,
    ProdutoSearchComponent,
    ProdutoItemThreeComponent
  ],
  imports: [
    ProdutoItemValorModule,
    LazyLoadImageModule,
    PipeModule,
    PartialModule,    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    ComboDataSelectModule,
    SelectDataModule
  ]
})
export class ProdutoPartialModule { }
