<div class="produto-item produto-item-three" (click)="_click()">
      <div class="content">
            <img *ngIf="produto.foto_capa == null" width="100%"  src="/images/produto/sem-foto/capa.jpg" alt="Sem Foto" class="img-fluid" />
            <img *ngIf="produto.foto_capa != null" [lazyLoad]="produto.foto_capa" defaultImage="/images/produto/sem-foto/capa.jpg" width="100%" />
      </div>
      <div class="footer"> 
            <div class="box">
                  <h3>{{produto.nome}}</h3> 
                  <article [innerHtml]="produto.descricao_listagem | safeHtml"></article>
            </div>  
      </div>
      <div class="overlay"></div>
</div>
