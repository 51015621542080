<div class="module-newsletter">
  <div class="container">
    <div class="content-form">
      <div class="row">
          <div class="col-md-4">
              <h3>{{data.titulo}}</h3>
          </div>
          <div class="col-md-8">  
              <form class="form-custom" (submit)="send()">
                  <div class="item item-input">
                      <label>{{'Nome' | translate}}</label>
                      <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                  </div>    
                  <div class="item item-input">
                      <label>{{'E-mail' | translate}}</label>
                      <input type="text" [(ngModel)]="dataItem.email" name="email" />    
                  </div>    
                  <div class="item item-button">
                      <button class="btn-one btn-radius"> 
                          <span>{{'Cadastrar' | translate}}</span>
                      </button>
                  </div>    
              </form> 
          </div>
      </div> 
    </div>  
  </div> 
</div> 
<loader *ngIf="loader"></loader>
