import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-main-item',
  templateUrl: './menu-main-item.component.html',
  styleUrls: ['./menu-main-item.component.scss']
})
export class MenuMainItemComponent implements OnInit {

  @Input("data") data:any = null;
  public defaultImage     = "/images/produto/sem-foto/default-capa.jpg";

  constructor(
    private router: Router
  ){}  

  /**
   * 
   * To router
   * 
   */ 
  toRoute(){

    switch (this.data.url_tipo) {
      case 1:

        this.router.navigateByUrl("/servicos/"+this.data.categoria_servico);
  
      break;
      case 2:

        window.open(this.data.url);

      break;
      case 3:

        this.router.navigateByUrl("/blog");
  
      break;
      default:
        
      break;
    }

  }
  /***
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}    

}
