import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { NewsletterComponent } from 'src/app/modulos/newsletter/newsletter.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    NewsletterComponent
  ],
  exports: [
    NewsletterComponent
  ],
  imports: [
    LoaderModule,
    LazyLoadImageModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    PipeModule
  ],
  providers:[
    ApiService,
    AppService
  ]
})
export class ModuloNewsletterModule { }
