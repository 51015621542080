<div [ngSwitch]="template">
  <section *ngSwitchCase="'home'" class="modulo modulo-contato-home default-home" itemscope itemtype="https://schema.org/Organization">
    <div class="row">
      <div class="col-md-4">
        <h3>{{'Nosso E-mail' | translate}}</h3>
        <div class="contato-item" (click)="openEmail()">
          <div class="icon bg-blue">
            <i class="fa-solid fa-envelope"></i>
          </div>   
          <div class="content"> 
            <span>{{data.params.email}}</span>
          </div> 
        </div>
      </div>
      <div class="col-md-4"> 
        <h3>{{'Fale por WhatsApp' | translate}}</h3>
        <div class="contato-item" (click)="openWhatsApp()">
          <div class="icon bg-blue">
            <i class="fa-brands fa-whatsapp"></i>
          </div>   
          <div class="content">
            <span>{{data.params.whatsapp}}</span>
          </div>
        </div> 
      </div>
      <div class="col-md-4">
        <h3>{{'Envie uma Mensagem' | translate}}</h3>
        <div class="contato-item" (click)="toContato()">
          <div class="icon bg-blue">
            <i class="fa-solid fa-message"></i>
          </div>   
          <div class="content">
            <span>{{'Clique Aqui' | translate}}</span>
          </div> 
        </div>
      </div>  
    </div>  
  </section>  
  <section *ngSwitchCase="'fale-conosco'" class="template-fale-conosco">
    <div class="content">
      <div class="item">
        <span>{{data.params.line_1}}</span>
      </div>
      <div class="item">
        <span>{{data.params.email}}</span>
      </div>
      <div class="item">
        <span>{{data.params.line_2}}</span>
      </div>
    </div>
  </section>
</div>  

