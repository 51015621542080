<div class="module module-frota-about">
    <div class="box">
        <div class="box-content">
            <div class="container">
                <div class="box-description">
                    <h2>{{data.titulo}}</h2>
                    <div class="content-button">
                        <a routerLink="/servicos" title="Serviços" class="btn-one btn-icon btn-radius">
                            <span>{{'Ver todos os serviços' | translate}}</span>
                        </a> 
                    </div> 
                </div>
            </div>
            <div class="foto">     
                <img defaultImage="/images/pixel.png" lazyLoad="/images/modulo/frota/{{paramsBR.imagem}}" alt="Frota" width="100%" />
            </div>    
        </div> 
    </div>
    <div class="box box-veiculos">
        <div class="box-title">
            <div class="container">
                <h2>{{data.params.titulo1}}</h2>
                <h3>{{data.params.titulo2}}</h3> 
            </div>
        </div>
        <div class="box-content">
            <div class="container">
                <div class="veiculos slider-itens" #slider>   
                    <div class="slider-item " *ngFor="let item of data.veiculos"> 
                        <veiculo-item [data]="item"></veiculo-item>     
                    </div>     
                </div>
                <div class="slide-navigate-item swiper-button-prev sw-btn" (click)="prev()">
                    <img src="/images/icons/prev.svg" alt="Arrow" width="15px" />  
                </div>
                <div class="slide-navigate-item swiper-button-next sw-btn" (click)="next()">
                    <img src="/images/icons/next.svg" alt="Arrow" width="15px" />
                </div>
            </div>
        </div>
    </div>
</div>    