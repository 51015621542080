<div class="menu-main-item" (click)="toRoute()">
      <div class="content">
            <img *ngIf="data.imagem == null" width="100%"  src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
            <img *ngIf="data.imagem != null" [lazyLoad]="data.imagem" defaultImage="/images/produto/sem-foto/default-capa.jpg" width="100%" />
      </div>
      <div class="footer">
            <h3>{{data.titulo}}</h3>  
            <div class="icon">
                  <img src="/images/icons/arrow-right-2.svg" title="Arrow" width="30px" />
            </div>
      </div>
      <div class="overlay"></div>
</div>