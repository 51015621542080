<div class="portfolio-item">
      <div class="capa"> 
          <a [href]="data.link" [title]="data.nome" target="_blank" *ngIf="data.link_ativo">
              <figure>
                  <img defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="data.foto" [alt]="data.nome" width="180px" height="122px" />
              </figure>
          </a>
          <figure *ngIf="!data.link_ativo">
                <img defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="data.foto" [alt]="data.nome" width="180px" height="122px" />
          </figure>   
      </div>
      <div class="content"> 
            <article [innerHtml]="data.texto"></article>
            <div class="saiba-mais" *ngIf="data.link_ativo"> 
                <a [href]="data.link" [title]="data.nome" target="_blank">
                    Saiba mais
                </a>  
            </div>
      </div>   
</div>