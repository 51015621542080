<a href="javascript::void();" (click)="_click()">
  <div class="produto-item {{classCustom}}">
    <div class="capa">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="100%"  defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>   
    </div>
    <div class="content">
      <div class="content-title">
        <h3>{{produto.nome}}</h3>  
        <article *ngIf="produto.descricao_listagem !== null && produto.descricao_listagem !== ''" [innerHtml]="produto.descricao_listagem | safeHtml"></article>
      </div>
      <div class="footer">
        <div class="box">
          <div class="tariff">
            <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
          </div>
        </div>   
        <div class="box"> 
          <a href="javascript::void();" alt="Ver produto" class="link-product">
            <span class="text">{{'Detalhes e Reservar' | translate}}</span>
            <img src="/images/icons/arrow-right.png" width="27px" height="15px" />
          </a>    
        </div>
      </div>    
    </div>    
</div>   
</a>   