import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuMainComponent } from 'src/app/modulos/menu-main/menu-main.component';
import { PartialModule } from '../../partial/partial.module';
import { MenuMainItemComponent } from 'src/app/components/menu-main/menu-main-item/menu-main-item.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    MenuMainComponent,
    MenuMainItemComponent
  ],
  exports: [
    MenuMainComponent,
    MenuMainItemComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    LazyLoadImageModule 
  ]
})
export class ModuloMenuMainModule{}   
