<div class="content-bottom-main">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <modulo posicao="rede-social"></modulo>
            </div>
            <div class="col-md-9"> 
                <modulo posicao="contato" template="home"></modulo>  
            </div>
        </div>
    </div>    
</div>
<footer>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-2 col-item">
                    <h3 class="title-h3">Links Úteis</h3>
                    <ul class="menu-list">   
                        <li>
                            <a routerLink="/contato" title="{{'Fale Conosco' | translate}}"> 
                                {{'Fale Conosco' | translate}}
                            </a>    
                        </li>
                        <li>
                            <a routerLink="quem-somos" title="{{'Sobre a suprematur' | translate}}">
                                {{'Sobre a Suprematur' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/termos-e-condicoes" title="{{'Termos e Condições'| translate}}">
                                {{'Termos e Condições' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-privacidade" title="{{'Políticas de Privacidade' | translate}}">
                                {{'Políticas de Privacidade' | translate}}
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-item">
                    <h3 class="title-h3">{{'Minha conta' | translate}}</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/minha-conta/pedidos" title="{{'Minhas Viagens' | translate}}">
                                {{'Minhas Viagens' | translate}}
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/carrinho" title="{{'Meu Carrinho' | translate}}">
                                {{'Meu Carrinho' | translate}}
                            </a>  
                        </li>
                        <li>
                            <a routerLink="/minha-conta" title="{{'Meu Perfil' | translate}}">
                                {{'Meu Perfil' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/duvidas-frequentes" title="Dúvidas Frequentes">
                                {{'Dúvidas Frequentes' | translate}}
                            </a> 
                        </li>
                    </ul>
                </div> 
                <div class="col-md-2 col-item">
                    <h3 class="title-h3">{{'Acesse' | translate}}</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/servicos/passeios" title="{{'Passeios' | translate}}">
                                {{'Passeios' | translate}}
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/servicos/transfers" title="{{'Transfers' | translate}}">
                                {{'Transfers' | translate}}
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/blog" title="{{'Nosso Blog' | translate}}">
                                {{'Nosso Blog' | translate}}
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 about">
                    <modulo posicao="sobre"></modulo>    
                </div>  
                <div class="col-md-3 address">
                    <h3 class="title-h3">{{'Endereço' | translate}}</h3>
                    <article>
                        <p>SRTVS Q 701 CONJ.L BL 2  ED. ASSIS CHATEAUBRIAND SALA 720 - PARTE, CEP: 70.340-907</p>
                    </article>
                </div>
            </div>
        </div>  
    </div>
    <div class="content-certificados">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-center">
                    <h3>{{'Fazemos parte de(o)' | translate}}:</h3>
                </div>
                <div class="col-md-7">
                   <ul>
                        <li *ngFor="let c of certificados">
                            <img [src]="c.image" [alt]="c.title" width="120px" height="90px"/>
                        </li>
                   </ul> 
                </div>  
            </div>
        </div>    
    </div>    
    <div class="bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="copyright">
                        <p class="cnpj"><strong>CNPJ: 05.666.393/0001-90 / SUPREMA LOCADORA E TURISMO LTDA</strong></p>
                        <p>Copyright © 2022 - Todos os direitos reservados</p>
                        <p>
                            <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                                By <strong>Digital Movement</strong>
                                <img src="images/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
                            </a>
                        </p>
                    </div>
                </div>
            </div>  
        </div>  
    </div>    
</footer> 
