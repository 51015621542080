import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnFavoritoComponent } from 'src/app/components/btns/btn-favorito/btn-favorito.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoaderModule } from '../../loader/loader/loader.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    BtnFavoritoComponent
  ],
  exports: [
    BtnFavoritoComponent
  ],
  imports: [
    LazyLoadImageModule,
    LoaderModule, 
    CommonModule,
    PipeModule
  ]
})
export class BtnFavoritoModule { }
