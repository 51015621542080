<div class="module module-atendimento-personalizado" [ngStyle]="{'background-image': 'url('+getImg()+')'}">
    <div class="module-content">
      <div class="container">
            <div class="row">
                <div class="col-md-6"> 
                    <modulo-horarios template="fale-conosco" [data]="moduloHorarios" *ngIf="moduloHorarios != null"></modulo-horarios> 
                    <modulo-contato [data]="moduloContato" template="fale-conosco" *ngIf="moduloContato != null"></modulo-contato> 
                </div>
                <div class="col-md-6">
                    <div class="content-form">
                        <h2>{{data.titulo}}</h2>
                        <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
                        <form class="form form-custom">
                            <div class="form-group">
                                <label>{{'Nome' | translate}}</label>
                                <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                            </div> 
                            <div class="form-group">
                                <label>{{'E-mail' | translate}}</label>
                                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="{{'Informe aqui o seu melhor e-mail' | translate}}" />
                            </div> 
                            <div class="form-group">
                                <label>{{'WhatsApp' | translate}}</label>
                                <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="(99) 99999-9999" mask="(99) 99999-9999" />
                            </div>  
                            <div class="form-group">
                                <label>{{'Mensagem' | translate}}</label>
                                <textarea [(ngModel)]="dataItem.mensagem" name="message" rows="7"></textarea>
                            </div>   
                            <div class="form-group">
                                <button type="button" class="btn-one btn-radius" (click)="send()">   
                                    <span>{{'Entre em contato' | translate}}</span>
                                </button>    
                            </div> 
                        </form>
                    </div> 
                </div>
            </div>  
        </div> 
    </div>
</div>
<loader *ngIf="loader"></loader>
