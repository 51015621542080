import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioLoginComponent } from 'src/app/components/usuario/usuario-login/usuario-login.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import { PartialModule } from '../../partial/partial.module';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DmInputModule } from '../../form/dm-input/dm-input.module';
import { BtnSocialLoginModule } from '../../btn/btn-social-login/btn-social-login.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [ 
    UsuarioLoginComponent    
  ],
  exports: [
    UsuarioLoginComponent
  ],
  imports: [  
    BtnSocialLoginModule,
    DirectivesModule,    
    LoaderModule,  
    LazyLoadImageModule, 
    PartialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DmInputModule,  
    PipeModule
  ]
})
export class UsuarioLoginModule { }
