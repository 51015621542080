<div class="modulo modulo-manu-main">
      <div class="modulo-content">
            <div class="container">
                  <div class="row">
                        <div class="col-md-4" *ngFor="let d of data.data">
                              <menu-main-item [data]="d"></menu-main-item>
                        </div>
                  </div>
            </div>
      </div>
</div>