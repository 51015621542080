<section class="page-home">  
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
  <modulo posicao="menu-main"></modulo>
  <div class="section-modulo" #containerModulos>  
    <div *ngIf="showContainer">
      <modulo posicao="produto-destaque-1"></modulo>
      <modulo posicao="frota"></modulo> 
      <modulo posicao="produto-destaque-2"></modulo>
      <modulo posicao="cliente"></modulo> 
      <modulo posicao="fale-conosco"></modulo> 
      <modulo posicao="newsletter"></modulo> 
    </div>
  </div>   
</section>   

 



